@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,800;1,200;1,400;1,800&display=swap');

body {
  font-family: 'Nunito' !important;
  font-size: medium;
}

@font-face {
  font-family: 'Basic Sans';
  src:
    local('Basic Sans'),
    url('/assets/fonts/basic-sans-700.woff2') format('woff2'),
    url('/assets/fonts/basic-sans-700.woff') format('woff');
  font-weight: 700;
}
